header .pc-header:hover .top-link .show-cont {
  color: #000;
  background-color: #e3e3e3;
}

@media (max-width: 1024px) {
  header .pc-header:hover .top-link .show-cont {
    color: #fff;
  }
}

@media (max-width: 1024px) {
  header .pc-header:hover .top-link .show-cont {
    background-color: transparent;
  }
}

header .pc-header:hover .header {
  background-color: #fff;
  border-bottom: solid #e8e8e8;
  border-width: 0.01rem;
}

@media (max-width: 1024px) {
  header .pc-header:hover .header {
    background-color: transparent;
  }
}

@media (max-width: 1024px) {
  header .pc-header:hover .header {
    border-bottom: solid transparent;
  }
}

@media (max-width: 1024px) {
  header .pc-header:hover .header {
    border-width: 0;
  }
}

header .pc-header:hover .header .left-logo img.logo-color {
  opacity: 1;
}

@media (max-width: 1024px) {
  header .pc-header:hover .header .left-logo img.logo-color {
    opacity: 0;
  }
}

header .pc-header:hover .header .left-logo img.logo-white {
  opacity: 0;
}

@media (max-width: 1024px) {
  header .pc-header:hover .header .left-logo img.logo-white {
    opacity: 1;
  }
}

header .pc-header:hover .header .right-cont .nav ul li .nav-title {
  color: #000;
}

@media (max-width: 1024px) {
  header .pc-header:hover .header .right-cont .nav ul li .nav-title {
    color: #fff;
  }
}

header .pc-header:hover .header .right-cont .right-fun .touxiang .login {
  color: #000;
}

@media (max-width: 1024px) {
  header .pc-header:hover .header .right-cont .right-fun .touxiang .login {
    color: #fff;
  }
}

header .pc-header:hover .header .right-cont .right-fun .nav-search .open-search .iconfont {
  color: #666666;
}

@media (max-width: 1024px) {
  header .pc-header:hover .header .right-cont .right-fun .nav-search .open-search .iconfont {
    color: #fff;
  }
}

header .pc-header:hover .header .right-cont .right-fun .hamburger > div, header .pc-header:hover .header .right-cont .right-fun .hamburger > div:after, header .pc-header:hover .header .right-cont .right-fun .hamburger > div:before, header .pc-header:hover .header .right-cont .right-fun .hamburger > view, header .pc-header:hover .header .right-cont .right-fun .hamburger > view:after, header .pc-header:hover .header .right-cont .right-fun .hamburger > view:before {
  color: #4c4c4c;
}

@media (max-width: 1024px) {
  header .pc-header:hover .header .right-cont .right-fun .hamburger > div, header .pc-header:hover .header .right-cont .right-fun .hamburger > div:after, header .pc-header:hover .header .right-cont .right-fun .hamburger > div:before, header .pc-header:hover .header .right-cont .right-fun .hamburger > view, header .pc-header:hover .header .right-cont .right-fun .hamburger > view:after, header .pc-header:hover .header .right-cont .right-fun .hamburger > view:before {
    color: #fff;
  }
}

header .pc-header.active .top-link .show-cont, header .pc-header.mob-active .top-link .show-cont {
  color: #000;
  background-color: #e3e3e3;
}

header .pc-header.active .header, header .pc-header.mob-active .header {
  background-color: #fff;
  border-bottom: solid #e8e8e8;
  border-width: 0.01rem;
}

@media (max-width: 1024px) {
  header .pc-header.active .header, header .pc-header.mob-active .header {
    border-width: 1px;
  }
}

header .pc-header.active .header .left-logo img.logo-color, header .pc-header.mob-active .header .left-logo img.logo-color {
  opacity: 1;
}

header .pc-header.active .header .left-logo img.logo-white, header .pc-header.mob-active .header .left-logo img.logo-white {
  opacity: 0;
}

header .pc-header.active .header .right-cont .nav ul li .nav-title, header .pc-header.mob-active .header .right-cont .nav ul li .nav-title {
  color: #000;
}

header .pc-header.active .header .right-cont .right-fun .touxiang .login, header .pc-header.mob-active .header .right-cont .right-fun .touxiang .login {
  color: #000;
}

header .pc-header.active .header .right-cont .right-fun .nav-search .open-search .iconfont, header .pc-header.mob-active .header .right-cont .right-fun .nav-search .open-search .iconfont {
  color: #666666;
}

header .pc-header.active .header .right-cont .right-fun .hamburger > div, header .pc-header.active .header .right-cont .right-fun .hamburger > div:after, header .pc-header.active .header .right-cont .right-fun .hamburger > div:before, header .pc-header.active .header .right-cont .right-fun .hamburger > view, header .pc-header.active .header .right-cont .right-fun .hamburger > view:after, header .pc-header.active .header .right-cont .right-fun .hamburger > view:before, header .pc-header.mob-active .header .right-cont .right-fun .hamburger > div, header .pc-header.mob-active .header .right-cont .right-fun .hamburger > div:after, header .pc-header.mob-active .header .right-cont .right-fun .hamburger > div:before, header .pc-header.mob-active .header .right-cont .right-fun .hamburger > view, header .pc-header.mob-active .header .right-cont .right-fun .hamburger > view:after, header .pc-header.mob-active .header .right-cont .right-fun .hamburger > view:before {
  background-color: #4c4c4c;
}

header .pc-header .header {
  background-color: transparent;
  border-bottom: none;
}

header .pc-header .header .left-logo img.logo-color {
  opacity: 0;
}

header .pc-header .header .left-logo img.logo-white {
  opacity: 1;
}

header .pc-header .header .right-cont {
  margin-top: 0;
}

header .pc-header .header .right-cont .nav ul li .nav-title {
  color: #fff;
}

header .pc-header .header .right-cont .right-fun .touxiang .login {
  color: #fff;
}

header .pc-header .header .right-cont .right-fun .nav-search .open-search .iconfont {
  color: #fff;
}

header .pc-header .header .right-cont .right-fun .hamburger > div, header .pc-header .header .right-cont .right-fun .hamburger > div:after, header .pc-header .header .right-cont .right-fun .hamburger > div:before, header .pc-header .header .right-cont .right-fun .hamburger > view, header .pc-header .header .right-cont .right-fun .hamburger > view:after, header .pc-header .header .right-cont .right-fun .hamburger > view:before {
  background-color: #fff;
}

.index-common-title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0.7rem;
}

.index-common-title .left {
  position: relative;
  color: #000;
  line-height: 1.4;
  flex-shrink: 0;
  margin-left: 0.2rem;
}

.index-common-title .left::after {
  content: '';
  display: block;
  background: url(../../assets/images/icon_title.png) no-repeat top center;
  background-size: contain;
  position: absolute;
  top: 0.08rem;
  right: calc(100% + 0.15rem);
  width: 0.22rem;
  height: 0.48rem;
}

@media (max-width: 1024px) {
  .index-common-title .left::after {
    top: 0.1rem;
  }
}

@media (max-width: 767px) {
  .index-common-title .left::after {
    top: 0.06rem;
  }
}

@media (max-width: 1024px) {
  .index-common-title .left::after {
    right: calc(100% + 0.1rem);
  }
}

@media (max-width: 767px) {
  .index-common-title .left::after {
    right: calc(100% + 0.05rem);
  }
}

.index-common-title .left h1 {
  font-weight: bold;
  font-size: 0.42rem;
}

@media (max-width: 1024px) {
  .index-common-title .left h1 {
    font-size: 24px;
  }
}

.index-common-title .left h2 {
  font-family: 'din';
  text-transform: uppercase;
  font-size: 0.28rem;
}

.index-banner {
  position: relative;
  margin-top: -0.4rem;
  height: 100vh;
}

@media (max-width: 1024px) {
  .index-banner {
    margin-top: 0;
  }
}

.index-banner .swiper {
  height: 100%;
}

.index-banner .swiper .swiper-slide {
  position: relative;
  overflow: hidden;
}

.index-banner .swiper .swiper-slide b, .index-banner .swiper .swiper-slide a {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.index-banner .swiper .swiper-slide b.pc-img, .index-banner .swiper .swiper-slide a.pc-img {
  display: block;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide b.pc-img, .index-banner .swiper .swiper-slide a.pc-img {
    display: none;
  }
}

.index-banner .swiper .swiper-slide b.mob-img, .index-banner .swiper .swiper-slide a.mob-img {
  display: none;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide b.mob-img, .index-banner .swiper .swiper-slide a.mob-img {
    display: block;
  }
}

.index-banner .swiper .swiper-slide .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .video {
    display: none;
  }
}

.index-banner .swiper .swiper-slide .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index-banner .swiper-cont {
  position: absolute;
  right: 0;
  z-index: 2;
  transform: translateY(-50%);
  pointer-events: none;
  top: 50%;
  left: 20%;
}

@media (max-width: 1024px) {
  .index-banner .swiper-cont {
    left: 10%;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-cont {
    left: 5%;
  }
}

.index-banner .swiper-cont .swiper-numpage {
  font-family: 'din';
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.02rem;
  display: none;
}

.index-banner .swiper-cont .swiper-numpage span {
  margin: 0 0.03rem;
}

.index-banner .swiper-cont .swiper-numpage .active-num {
  color: #fff;
  font-size: 0.28rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper-cont .swiper-numpage .active-num {
    font-size: 20px;
  }
}

.index-banner .swiper-cont .swiper-t {
  color: #fff;
  position: relative;
  margin-top: 0.35rem;
  margin-bottom: 0.35rem;
  height: 2.37rem;
  letter-spacing: 0.03rem;
  font-size: 0.76rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper-cont .swiper-t {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-cont .swiper-t {
    height: 250px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-cont .swiper-t {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-cont .swiper-t {
    font-size: 32px;
  }
}

.index-banner .swiper-cont .swiper-t .item {
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0;
  transition: transform .7s, opacity .7s;
  transform: translateX(5%) translateY(-50%);
  display: block;
  padding-right: 10%;
}

@media (max-width: 767px) {
  .index-banner .swiper-cont .swiper-t .item {
    width: 95vw;
  }
}

.index-banner .swiper-cont .swiper-t .item b {
  display: inline-block;
}

.index-banner .swiper-cont .swiper-t .item.active {
  opacity: 1;
  transform: translateX(0) translateY(-50%);
}

.index-banner .swiper-cont .swiper-pagination .swiper-pagination-bullet {
  border-radius: 0;
  background: transparent;
  position: relative;
  opacity: 1;
  pointer-events: all;
  width: 0.4rem;
  height: 0.4rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper-cont .swiper-pagination .swiper-pagination-bullet {
    width: 30px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-cont .swiper-pagination .swiper-pagination-bullet {
    height: 20px;
  }
}

.index-banner .swiper-cont .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transition: background-color .3s,font-size .3s;
  transform: translate3d(-50%, -50%, 0);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  width: 0.12rem;
  height: 0.12rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper-cont .swiper-pagination .swiper-pagination-bullet::after {
    width: 10px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-cont .swiper-pagination .swiper-pagination-bullet::after {
    height: 10px;
  }
}

.index-banner .swiper-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  content: '\e611';
  font-family: 'iconfont';
  background-color: transparent;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    font-size: 18px;
  }
}

.index-banner .mob-down-icon {
  position: absolute;
  left: 5%;
  bottom: 5%;
  color: #fff;
  z-index: 1;
  transform: rotate(-90deg);
  display: none;
}

@media (max-width: 1024px) {
  .index-banner .mob-down-icon {
    display: block;
  }
}

.index-banner .mob-down-icon .iconfont {
  font-size: 20px;
}

.section1 {
  position: relative;
  overflow: hidden;
  z-index: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (max-width: 767px) {
  .section1 {
    padding-top: 0.5rem;
  }
}

@media (max-width: 991px) {
  .section1 {
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-bottom: 0.5rem;
  }
}

.section1 .cont {
  position: relative;
  display: block;
}

@media (max-width: 991px) {
  .section1 .cont {
    display: none;
  }
}

.section1 .cont .swiper-tab {
  position: absolute;
  top: 0;
  right: 0;
  display: black;
}

@media (max-width: 991px) {
  .section1 .cont .swiper-tab {
    display: none;
  }
}

.section1 .cont .swiper-tab .item {
  color: #7f7f7f;
  margin-bottom: 0.05rem;
  cursor: pointer;
  position: relative;
  transition: color .4s;
  font-size: 0.2rem;
}

.section1 .cont .swiper-tab .item:hover {
  color: #000;
}

.section1 .cont .swiper-tab .item.active {
  color: #000;
}

.section1 .cont .swiper-tab .item.active::after {
  transform: scaleX(1);
}

.section1 .cont .swiper-tab .item.active span {
  color: #cb4444;
}

.section1 .cont .swiper-tab .item::after {
  content: '';
  display: block;
  background-color: #cb4444;
  position: absolute;
  top: calc(50% - 0.01rem);
  transition: transform .4s;
  transform: scaleX(0);
  transform-origin: right center;
  right: calc(100% + 0.15rem);
  width: 0.2rem;
  height: 0.02rem;
}

.section1 .cont .swiper-tab .item span {
  font-family: 'din';
  margin-right: 0.2rem;
}

.section1 .cont .swiper-img {
  position: relative;
  transform: translateX(-14%);
  width: 80%;
}

@media (max-width: 1366px) {
  .section1 .cont .swiper-img {
    transform: translateX(-7%);
  }
}

@media (max-width: 991px) {
  .section1 .cont .swiper-img {
    transform: translateX(0);
  }
}

@media (max-width: 991px) {
  .section1 .cont .swiper-img {
    width: 100%;
  }
}

.section1 .cont .swiper-img .white-mask {
  width: 101%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: -1%;
  z-index: 2;
  pointer-events: none;
  transform-origin: right;
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.section1 .cont .swiper-img .white-mask.hide {
  transform: scaleX(0);
}

.section1 .cont .swiper-img .swiper {
  overflow: hidden;
}

.section1 .cont .swiper-text {
  z-index: 3;
  background-color: #fff;
  position: absolute;
  bottom: -0.3rem;
  left: 52.5%;
  width: 5.7rem;
}

@media (max-width: 991px) {
  .section1 .cont .swiper-text {
    position: relative;
  }
}

@media (max-width: 991px) {
  .section1 .cont .swiper-text {
    bottom: 0;
  }
}

@media (max-width: 991px) {
  .section1 .cont .swiper-text {
    left: 0;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .swiper-text {
    width: 5rem;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .swiper-text {
    width: 4.6rem;
  }
}

@media (max-width: 991px) {
  .section1 .cont .swiper-text {
    width: 100%;
  }
}

.section1 .cont .swiper-text .swiper {
  overflow: hidden;
  height: 4.9rem;
}

@media (max-width: 991px) {
  .section1 .cont .swiper-text .swiper {
    height: 5.6rem;
  }
}

.section1 .cont .swiper-text .swiper .t {
  padding-left: 1rem;
  padding-right: 0.2rem;
  padding-top: 0.6rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .section1 .cont .swiper-text .swiper .t {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .swiper-text .swiper .t {
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .swiper-text .swiper .t {
    padding-top: 15px;
  }
}

@media (max-width: 767px) {
  .section1 .cont .swiper-text .swiper .t {
    padding-bottom: 15px;
  }
}

.section1 .cont .swiper-text .swiper .t .num {
  font-family: 'din';
  display: flex;
  align-items: center;
  color: #000;
  font-size: 0.3rem;
}

.section1 .cont .swiper-text .swiper .t .num::before {
  content: '';
  display: block;
  background-color: #000;
  width: 0.24rem;
  height: 0.03rem;
  margin-right: 0.05rem;
}

.section1 .cont .swiper-text .swiper .t .title {
  color: #000;
  font-weight: bold;
  letter-spacing: 0.04rem;
  font-size: 0.48rem;
  margin-bottom: 0.2rem;
  margin-top: 0.4rem;
}

.section1 .cont .swiper-text .swiper .t .desc {
  color: #333;
  letter-spacing: 0.03rem;
  height: 1.65rem;
  line-height: 2.4;
  font-size: 0.2rem;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    height: 0.86rem;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    height: 68px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    line-height: 2.24;
  }
}

@media (max-width: 1366px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    line-height: 2.08;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    line-height: 1.92;
  }
}

@media (max-width: 991px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    line-height: 1.76;
  }
}

@media (max-width: 767px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    line-height: 1.6;
  }
}

@media (max-width: 1024px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    margin-bottom: 0.2rem;
  }
}

@media (max-width: 767px) {
  .section1 .cont .swiper-text .swiper .t .desc {
    margin-bottom: 20px;
  }
}

.section1 .cont .swiper-text .swiper .t .link {
  letter-spacing: 0.02rem;
  font-size: 0.18rem;
}

@media (max-width: 1024px) {
  .section1 .cont .swiper-text .swiper .t .link {
    font-size: 18px;
  }
}

.section1 .cont .swiper-text .swiper .t .link a {
  color: #384dc3;
  transition: color .3s;
}

.section1 .cont .swiper-text .swiper .t .link a:hover {
  color: #cb4444;
}

.section1 .cont .swiper-text .swiper .t .link a:hover .iconfont {
  transform: translateX(5px);
}

.section1 .cont .swiper-text .swiper .t .link a .iconfont {
  transition: transform .3s;
  display: inline-block;
  margin-left: 0.05rem;
  font-size: 0.16rem;
}

.section1 .cont .swiper-text .swiper-btn {
  border: 1px solid #c6c4d0;
  position: absolute;
  display: flex;
  align-items: stretch;
  transition: all .3s;
  right: 100%;
  top: calc(100% - 0.3rem);
}

@media (max-width: 991px) {
  .section1 .cont .swiper-text .swiper-btn {
    right: 0;
  }
}

@media (max-width: 991px) {
  .section1 .cont .swiper-text .swiper-btn {
    top: 0;
  }
}

.section1 .cont .swiper-text .swiper-btn:hover {
  border: 1px solid #384dc3;
  background-color: #384dc3;
}

.section1 .cont .swiper-text .swiper-btn:hover::after {
  background-color: rgba(255, 255, 255, 0.7);
}

.section1 .cont .swiper-text .swiper-btn:hover .swiper-button-prev, .section1 .cont .swiper-text .swiper-btn:hover .swiper-button-next {
  color: #fff;
}

.section1 .cont .swiper-text .swiper-btn::after {
  content: '';
  display: block;
  background-color: #939296;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 0.02rem;
  height: 0.17rem;
}

.section1 .cont .swiper-text .swiper-btn .swiper-button-prev, .section1 .cont .swiper-text .swiper-btn .swiper-button-next {
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-image: none;
  transition: color .3s;
  color: #626264;
  width: 1.55rem;
  height: 0.85rem;
}

.section1 .cont .swiper-text .swiper-btn .swiper-button-prev::after, .section1 .cont .swiper-text .swiper-btn .swiper-button-next::after {
  font-family: 'iconfont';
  transition: transform .3s;
  display: inline-block;
  font-size: 0.3rem;
}

.section1 .cont .swiper-text .swiper-btn .swiper-button-prev::after {
  content: '\e625';
}

.section1 .cont .swiper-text .swiper-btn .swiper-button-prev:hover::after {
  transform: translateX(-0.05rem);
}

.section1 .cont .swiper-text .swiper-btn .swiper-button-next::after {
  content: '\e624';
}

.section1 .cont .swiper-text .swiper-btn .swiper-button-next:hover::after {
  transform: translateX(0.1rem);
}

.section1 .mob-cont {
  display: none;
}

@media (max-width: 991px) {
  .section1 .mob-cont {
    display: block;
  }
}

.section1 .mob-cont ul {
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
}

@media (max-width: 767px) {
  .section1 .mob-cont ul {
    display: block;
  }
}

.section1 .mob-cont ul li {
  flex-shrink: 0;
  position: relative;
  width: 48%;
  margin-bottom: 4%;
}

@media (max-width: 767px) {
  .section1 .mob-cont ul li {
    width: 100%;
  }
}

.section1 .mob-cont ul li .item-t {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  color: #fff;
  padding-top: 10%;
  padding-left: 10%;
}

.section1 .mob-cont ul li .item-t .num {
  display: flex;
  align-items: center;
  font-family: 'din';
}

.section1 .mob-cont ul li .item-t .num::before {
  content: '';
  display: block;
  width: 14px;
  height: 2px;
  background-color: #fff;
  margin-right: 5px;
}

.section1 .mob-cont ul li .item-t .title {
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.section1 .mob-cont ul li .item-t .desc {
  opacity: .8;
  margin-bottom: 15px;
}

.section1 .mob-cont ul li .item-t .link {
  font-size: 14px;
}

.section1 .mob-cont ul li .item-t .link .iconfont {
  font-size: 12px;
  margin-left: 5px;
}

.section2 {
  position: relative;
  padding-top: 1.5rem;
}

@media (max-width: 767px) {
  .section2 {
    padding-top: 0.5rem;
  }
}

.section2 .index-common-title {
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .section2 .index-common-title .common-link {
    display: none;
  }
}

.section2 .cont {
  position: relative;
  z-index: 2;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding-top: 0.4rem;
  padding-left: 0.4rem;
  padding-bottom: 0.4rem;
}

@media (max-width: 991px) {
  .section2 .cont {
    background-color: transparent;
  }
}

@media (max-width: 767px) {
  .section2 .cont {
    flex-direction: column-reverse;
  }
}

@media (max-width: 991px) {
  .section2 .cont {
    padding-top: 0;
  }
}

@media (max-width: 991px) {
  .section2 .cont {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .section2 .cont {
    padding-bottom: 0;
  }
}

.section2 .cont::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: calc(100% - 1px);
  display: block;
}

@media (max-width: 991px) {
  .section2 .cont::after {
    display: none;
  }
}

.section2 .cont .index-new {
  flex-shrink: 0;
  background-color: #f8f8f8;
  position: relative;
  width: 29%;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .section2 .cont .index-new {
    width: 48%;
  }
}

@media (max-width: 767px) {
  .section2 .cont .index-new {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section2 .cont .index-new {
    margin-bottom: 15px;
  }
}

.section2 .cont .index-new:first-child {
  width: 38%;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .section2 .cont .index-new:first-child {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section2 .cont .index-new:first-child {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .index-new:first-child {
    margin-bottom: 0;
  }
}

.section2 .cont .index-new:first-child .item-tag {
  background-color: #384dc3;
}

.section2 .cont .index-new:hover .item-img b {
  transform: scale(1.1);
}

.section2 .cont .index-new:hover .item-t .item-title {
  color: #384dc3;
}

.section2 .cont .index-new .item-tag {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  line-height: 2;
  white-space: nowrap;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

@media (max-width: 991px) {
  .section2 .cont .index-new .item-tag {
    font-size: 14px;
  }
}

.section2 .cont .index-new ul {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

@media (max-width: 991px) {
  .section2 .cont .index-new ul {
    padding-top: 35px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .index-new ul {
    padding-bottom: 15px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .index-new ul {
    padding-left: 15px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .index-new ul {
    padding-right: 15px;
  }
}

.section2 .cont .index-new ul li:not(:last-child) {
  margin-bottom: 0.25rem;
}

.section2 .cont .index-new ul li:hover .item-title {
  color: #384dc3;
}

.section2 .cont .index-new ul li .item-title {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color .4s;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .section2 .cont .index-new ul li .item-title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .index-new ul li .item-title {
    font-size: 16px;
  }
}

.section2 .cont .index-new ul li .item-date {
  color: #7c7c7c;
  margin-top: 0.05rem;
}

@media (max-width: 767px) {
  .section2 .cont .index-new ul li .item-date {
    font-size: 12px;
  }
}

.section2 .cont .index-new .item-t {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

@media (max-width: 991px) {
  .section2 .cont .index-new .item-t {
    padding-left: 15px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .index-new .item-t {
    padding-right: 15px;
  }
}

.section2 .cont .index-new .item-t .item-title {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all .4s;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .section2 .cont .index-new .item-t .item-title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .index-new .item-t .item-title {
    font-size: 16px;
  }
}

.section2 .cont .index-new .item-t .item-date {
  color: #7c7c7c;
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
  margin-bottom: 0.15rem;
}

@media (max-width: 767px) {
  .section2 .cont .index-new .item-t .item-date {
    font-size: 12px;
  }
}

.section2 .cont .index-new .item-t .item-date .iconfont {
  font-size: 12px;
  margin-right: 0.1rem;
}

.section2 .cont .index-new .item-t .item-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  color: #666;
  text-align: justify;
}

@media (max-width: 767px) {
  .section2 .cont .index-new .item-t .item-desc {
    font-size: 14px;
  }
}

.section2 .cont .index-new .mob-more {
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  color: #384dc3;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: none;
}

@media (max-width: 767px) {
  .section2 .cont .index-new .mob-more {
    display: flex;
  }
}

.section2 .cont .index-new .mob-more .iconfont {
  font-size: 14px;
  margin-left: 10px;
}

.section2 .bg-canvas {
  width: 100%;
  position: absolute;
  left: 0;
  overflow: hidden;
  background-size: 100% auto !important;
  bottom: 30%;
  background: url(./images/index_img5.png) no-repeat bottom center #f5f4fa;
  height: 100vh;
}

@media (max-width: 767px) {
  .section2 .bg-canvas {
    bottom: 50%;
  }
}

@media (max-width: 991px) {
  .section2 .bg-canvas {
    background: url(./images/index_img5_mob.png) no-repeat bottom center #f5f4fa;
  }
}

@media (max-width: 767px) {
  .section2 .bg-canvas {
    height: 100%;
  }
}

.section3 {
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-size: 100% auto !important;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  display: flex;
}

@media (max-width: 767px) {
  .section3 {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .section3 {
    display: block;
  }
}

@media (max-width: 991px) {
  .section3 {
    background: url("./images/index_mob_img5.jpg") no-repeat bottom center;
  }
}

.section3.container {
  padding-right: 0;
}

@media screen and (max-width: 992px) {
  .section3.container {
    padding-right: 12.5%;
  }
}

@media screen and (max-width: 992px) and (max-width: 1600px) {
  .section3.container {
    padding-right: 11%;
  }
}

@media screen and (max-width: 992px) and (max-width: 1366px) {
  .section3.container {
    padding-right: 9.5%;
  }
}

@media screen and (max-width: 992px) and (max-width: 1024px) {
  .section3.container {
    padding-right: 8%;
  }
}

@media screen and (max-width: 992px) and (max-width: 991px) {
  .section3.container {
    padding-right: 6.5%;
  }
}

@media screen and (max-width: 992px) and (max-width: 767px) {
  .section3.container {
    padding-right: 5%;
  }
}

.section3 .cont {
  position: relative;
  z-index: 2;
  display: block;
  margin-right: 5%;
}

@media (max-width: 991px) {
  .section3 .cont {
    display: none;
  }
}

@media (max-width: 1024px) {
  .section3 .cont {
    margin-right: 5%;
  }
}

@media (max-width: 991px) {
  .section3 .cont {
    margin-right: 0;
  }
}

.section3 .cont .sec3-desc {
  width: 5.7rem;
  padding-left: 0.2rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 991px) {
  .section3 .cont .sec3-desc {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section3 .cont .sec3-desc {
    margin-bottom: 0.5rem;
  }
}

.section3 .cont .sec3-desc .t {
  line-height: 1.8;
  font-size: 0.2rem;
  margin-bottom: 0.45rem;
  text-align: justify;
}

@media (max-width: 1600px) {
  .section3 .cont .sec3-desc .t {
    line-height: 1.76;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .sec3-desc .t {
    line-height: 1.72;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .sec3-desc .t {
    line-height: 1.68;
  }
}

@media (max-width: 991px) {
  .section3 .cont .sec3-desc .t {
    line-height: 1.64;
  }
}

@media (max-width: 767px) {
  .section3 .cont .sec3-desc .t {
    line-height: 1.6;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .sec3-desc .t {
    font-size: 16px;
  }
}

.section3 .cont .sec3-desc2 {
  border-left: solid #384dc3;
  white-space: nowrap;
  line-height: 1.8;
  font-size: 0.2rem;
  border-left-width: 0.04rem;
  margin-left: 0.2rem;
  padding-left: 0.35rem;
}

@media (max-width: 1024px) {
  .section3 .cont .sec3-desc2 {
    white-space: normal;
  }
}

@media (max-width: 1600px) {
  .section3 .cont .sec3-desc2 {
    line-height: 1.76;
  }
}

@media (max-width: 1366px) {
  .section3 .cont .sec3-desc2 {
    line-height: 1.72;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .sec3-desc2 {
    line-height: 1.68;
  }
}

@media (max-width: 991px) {
  .section3 .cont .sec3-desc2 {
    line-height: 1.64;
  }
}

@media (max-width: 767px) {
  .section3 .cont .sec3-desc2 {
    line-height: 1.6;
  }
}

@media (max-width: 1024px) {
  .section3 .cont .sec3-desc2 {
    font-size: 16px;
  }
}

.section3 .cont .sec3-desc2 .num {
  font-family: 'din';
  line-height: .8;
  font-size: 0.72rem;
  margin-bottom: 0.35rem;
}

.section3 .bg {
  flex-shrink: 0;
  position: relative;
  display: block;
  width: 8.8rem;
  margin-top: 0;
}

@media (max-width: 991px) {
  .section3 .bg {
    display: none;
  }
}

@media (max-width: 767px) {
  .section3 .bg {
    width: 300px;
  }
}

@media (max-width: 991px) {
  .section3 .bg {
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .section3 .bg {
    margin-top: 20px;
  }
}

.section3 .bg img {
  display: block;
  width: 100%;
  height: auto;
}

.section3 .bg ul li {
  position: absolute;
  top: 1%;
  left: 1%;
  width: 0.01rem;
  height: 0.01rem;
}

@media (max-width: 1024px) {
  .section3 .bg ul li {
    width: 1px;
  }
}

@media (max-width: 1024px) {
  .section3 .bg ul li {
    height: 1px;
  }
}

.section3 .bg ul li span {
  display: block;
  border-radius: 50%;
  background-color: #384dc3;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate3d(-50%, -50%, 0);
  width: 0.18rem;
  height: 0.18rem;
}

@media (max-width: 1024px) {
  .section3 .bg ul li span {
    width: 10px;
  }
}

@media (max-width: 767px) {
  .section3 .bg ul li span {
    width: 6px;
  }
}

@media (max-width: 1024px) {
  .section3 .bg ul li span {
    height: 10px;
  }
}

@media (max-width: 767px) {
  .section3 .bg ul li span {
    height: 6px;
  }
}

.section3 .bg ul li::before, .section3 .bg ul li::after {
  content: '';
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0);
  background-color: rgba(74, 93, 200, 0.6);
  z-index: 1;
  transform-origin: center;
  animation: wave 2s infinite;
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  width: 0.7rem;
  height: 0.7rem;
}

@media (max-width: 1024px) {
  .section3 .bg ul li::before, .section3 .bg ul li::after {
    width: 50px;
  }
}

@media (max-width: 767px) {
  .section3 .bg ul li::before, .section3 .bg ul li::after {
    width: 30px;
  }
}

@media (max-width: 1024px) {
  .section3 .bg ul li::before, .section3 .bg ul li::after {
    height: 50px;
  }
}

@media (max-width: 767px) {
  .section3 .bg ul li::before, .section3 .bg ul li::after {
    height: 30px;
  }
}

.section3 .bg ul li:nth-child(4n+1)::before {
  animation-delay: 0s;
}

.section3 .bg ul li:nth-child(4n+1)::after {
  animation-delay: .8s;
}

.section3 .bg ul li:nth-child(4n+2)::before {
  animation-delay: .5s;
}

.section3 .bg ul li:nth-child(4n+2)::after {
  animation-delay: 1.3s;
}

.section3 .bg ul li:nth-child(4n+3)::before {
  animation-delay: 1s;
}

.section3 .bg ul li:nth-child(4n+3)::after {
  animation-delay: 1.8s;
}

.section3 .bg ul li:nth-child(4n+4)::before {
  animation-delay: 1.5s;
}

.section3 .bg ul li:nth-child(4n+4)::after {
  animation-delay: 2.3s;
}

.section3 .bg ul li:nth-child(1) {
  top: 22%;
  left: 18.5%;
}

.section3 .bg ul li:nth-child(2) {
  top: 4%;
  left: 42%;
}

.section3 .bg ul li:nth-child(3) {
  top: 4%;
  left: 79.5%;
}

.section3 .bg ul li:nth-child(4) {
  top: 14.5%;
  left: 87%;
}

.section3 .bg ul li:nth-child(5) {
  top: 26%;
  left: 79%;
}

.section3 .bg ul li:nth-child(6) {
  top: 19.5%;
  left: 63.5%;
}

.section3 .bg ul li:nth-child(7) {
  top: 26.5%;
  left: 50%;
}

.section3 .bg ul li:nth-child(8) {
  top: 50%;
  left: 50.5%;
}

.section3 .bg ul li:nth-child(9) {
  top: 61.5%;
  left: 60.5%;
}

.section3 .bg ul li:nth-child(10) {
  top: 64.5%;
  left: 73%;
}

.section3 .bg ul li:nth-child(11) {
  top: 56%;
  left: 83%;
}

.section3 .bg ul li:nth-child(12) {
  top: 80%;
  left: 81.5%;
}

.section3 .mob-cont {
  display: none;
}

@media (max-width: 991px) {
  .section3 .mob-cont {
    display: block;
  }
}

.section3 .mob-cont .index-common-title {
  text-align: center;
}

.section3 .mob-cont .desc-t {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.section3 .mob-cont .num-cont {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
}

.section3 .mob-cont .num-cont li {
  flex-shrink: 0;
  width: 50%;
  margin-bottom: 20px;
}

.section3 .mob-cont .num-cont li .num {
  color: #384dc3;
  font-family: 'din';
  font-size: 30px;
}

.section3 .mob-cont .num-cont li p {
  color: #666;
  font-size: 14px;
}

@keyframes wave {
  0% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}

footer .friend-link {
  display: block;
}

@media (max-width: 1024px) {
  footer .friend-link {
    display: none;
  }
}
