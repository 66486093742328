@import "../../assets/styles/utils";

header{
  .pc-header{
    &:hover{
      .top-link{
        .show-cont{
          @include res(color,#000,(md:#fff));
          @include res(background-color,#e3e3e3,(md:transparent));
        }
      }
      .header{
        @include res(background-color,#fff,(md:transparent));
        @include res(border-bottom,solid #e8e8e8,(md:solid transparent));
        @include res(border-width,.01rem,(md:0));
        .left-logo{
          img{
            &.logo-color{
              @include res(opacity,1,(md:0));
            }
            &.logo-white{
              @include res(opacity,0,(md:1));
            }
          }
        }
        .right-cont{
          .nav{
            ul li .nav-title{
              @include res(color,#000,(md:#fff));
            }
          }
          .right-fun{
            .touxiang .login{
              @include res(color,#000,(md:#fff));
            }
            .nav-search{
              .open-search .iconfont{
                @include res(color,#666666,(md:#fff));
              }
            }
            .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
              @include res(color,#4c4c4c,(md:#fff));
            }
          }
        }
      }
    }
    &.active,&.mob-active{
      .top-link{
        .show-cont{
          color: #000;
          background-color: #e3e3e3;
        }
      }
      .header{
        background-color: #fff;
        border-bottom: solid #e8e8e8;
        @include res(border-width,.01rem,(md:1px));
        .left-logo{
          img{
            &.logo-color{
              opacity: 1;
            }
            &.logo-white{
              opacity: 0;
            }
          }
        }
        .right-cont{
          .nav{
            ul li .nav-title{
              color: #000;
            }
          }
          .right-fun{
            .touxiang .login{
              color: #000;
            }
            .nav-search{
              .open-search .iconfont{
                color: #666666;
              }
            }
            .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
              background-color: #4c4c4c;
            }
          }
        }
      }
    }
    .header{
      background-color: transparent;
      border-bottom: none;
      .left-logo{
        img{
          &.logo-color{
            opacity: 0;
          }
          &.logo-white{
            opacity: 1;
          }
        }
      }
      .right-cont{
        margin-top: 0;
        .nav{
          ul li .nav-title{
            color: #fff;
          }
        }
        .right-fun{
          .touxiang .login{
            color: #fff;
          }
          .nav-search{
            .open-search .iconfont{
              color: #fff;
            }
          }
          .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
            background-color: #fff;
          }
        }
      }
    }
  }
}

.index-common-title{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  @include res(margin-bottom, 0.7rem);
  .left{
    position: relative;
    color: #000;
    line-height: 1.4;
    flex-shrink: 0;
    @include res(margin-left, 0.2rem);
    &::after{
      content: '';
      display: block;
      background: url(../../assets/images/icon_title.png) no-repeat top center;
      background-size: contain;
      position: absolute;
      @include res(top,0.08rem,(md:0.1rem,xs:0.06rem));
      @include res(right,calc(100% + 0.15rem),(md:calc(100% + 0.1rem),xs:calc(100% + 0.05rem)));
      @include res(width, 0.22rem);
      @include res(height, 0.48rem);
    }
    h1{
      font-weight: bold;
      @include res(font-size,0.42rem,(md:24px));
    }
    h2{
      font-family: 'din';
      text-transform: uppercase;
      @include res(font-size,0.28rem);
    }
  }
}

.index-banner{
  position: relative;
  @include res(margin-top,-0.4rem,(md:0));
  @include res(height, 100vh);
  .swiper{
    height: 100%;
    .swiper-slide{
      position: relative;
      overflow: hidden;
      // &::after{
      //   content: '';
      //   display: block;
      //   width: 100%;
      //   height: 100%;
      //   background-color: rgba(0,0,0,0);
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   pointer-events: none;
      // }
      b,a{
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        &.pc-img{
          @include res(display,block,(sm:none));
        }
        &.mob-img{
          @include res(display,none,(sm:block));
        }
      }
      .video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @include res(display,block,(sm:none));
        video{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .swiper-cont{
    position: absolute;
    right: 0;
    z-index: 2;
    transform: translateY(-50%);
    pointer-events: none;
    @include res(top,50%);
    @include res(left,20%,(md:10%,xs:5%));
    .swiper-numpage{
      font-family: 'din';
      color: rgba(255,255,255,.5);
      letter-spacing: 0.02rem;
      display: none;
      span{
        margin: 0 0.03rem;
      }
      .active-num{
        color: #fff;
        @include res(font-size,0.28rem,(md:20px));
      }
    }
    .swiper-t{
      color: #fff;
      // overflow: hidden;
      position: relative;
      // @include res(white-space,nowrap,(xs:wrap));
      @include res(margin-top,0.35rem);
      @include res(margin-bottom,0.35rem);
      @include res(height, 2.37rem,(md:300px,xs:250px));
      @include res(letter-spacing,0.03rem);
      @include res(font-size,0.76rem,(md:40px,xs:32px));
      .item{
        position: absolute;
        top: 50%;
        left: 0;
        opacity: 0;
        transition: transform .7s, opacity .7s;
        transform: translateX(5%) translateY(-50%);
        display: block;
        @include res(width,null,(xs:95vw));
        @include res(padding-right,10%);
        // @include res(white-space,nowrap,(xs:wrap));
        b{
          display: inline-block;
        }
        &.active{
          opacity: 1;
          transform: translateX(0) translateY(-50%);
        }
      }
    }
    .swiper-pagination{
      .swiper-pagination-bullet{
        border-radius: 0;
        background: transparent;
        position: relative;
        opacity: 1;
        pointer-events: all;
        @include res(width,0.4rem,(md:30px));
        @include res(height,0.4rem,(md:20px));
        &::after{
          content: '';
          border-radius: 50%;
          background-color: rgba(255,255,255,.2);
          position: absolute;
          top: 50%;
          left: 50%;
          transition: background-color .3s,font-size .3s;
          transform: translate3d(-50%,-50%,0);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          @include res(width,0.12rem,(md:10px));
          @include res(height,0.12rem,(md:10px));
        }
        &.swiper-pagination-bullet-active{
          &::after{
            content: '\e611';
            font-family: 'iconfont';
            background-color: transparent;
            @include res(font-size,0.2rem,(md:18px));
          }
        }
      }
    }
  }
  .mob-down-icon{
    position: absolute;
    left: 5%;
    bottom: 5%;
    color: #fff;
    z-index: 1;
    transform: rotate(-90deg);
    @include res(display,none,(md:block));
    .iconfont{
      font-size: 20px;
    }
  }
}

.section1{
  position: relative;
  overflow: hidden;
  z-index: 2;
  @include res(padding-top,1.5rem,(xs:0.5rem));
  @include res(padding-bottom,1.5rem,(sm:.5rem,xs:0.5rem));
  .cont{
    position: relative;
    @include res(display,block,(sm:none));
    .swiper-tab{
      position: absolute;
      top: 0;
      right: 0;
      @include res(display,black,(sm:none));
      .item{
        color: #7f7f7f;
        margin-bottom: 0.05rem;
        cursor: pointer;
        position: relative;
        transition: color .4s;
        @include res(font-size, 0.2rem);
        &:hover{
          color: #000;
        }
        &.active{
          color: #000;
          &::after{
            transform: scaleX(1);
          }
          span{
            color: #cb4444;
          }
        }
        &::after{
          content: '';
          display: block;
          background-color: #cb4444;
          position: absolute;
          top: calc(50% - 0.01rem);
          transition: transform .4s;
          transform: scaleX(0);
          transform-origin: right center;
          @include res(right,calc(100% + 0.15rem));
          @include res(width,0.2rem);
          @include res(height,0.02rem);
        }
        span{
          font-family: 'din';
          @include res(margin-right,0.2rem);
        }
      }
    }
    .swiper-img{
      position: relative;
      @include res(transform,translateX(-14%),(mmd:translateX(-7%),sm:translateX(0)));
      @include res(width,80%,(sm:100%));
      .white-mask{
        width: 101%;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        right: -1%;
        z-index: 2;
        pointer-events: none;
        transform-origin: right;
        transition: transform 1s $anime-bezier;
        &.hide{
          transform: scaleX(0);
        }
      }
      .swiper{
        overflow: hidden;
      }
    }
    .swiper-text{
      z-index: 3;
      background-color: #fff;
      @include res(position,absolute,(sm:relative));
      @include res(bottom,-0.3rem,(sm:0));
      @include res(left,52.5%,(sm:0));
      @include res(width,5.7rem,(lg:5rem,mmd:4.6rem,sm:100%));
      .swiper{
        overflow: hidden;
        @include res(height,4.9rem,(sm:5.6rem));
        .t{
          @include res(padding-left,1rem,(xs:15px));
          @include res(padding-right,0.2rem,(xs:15px));
          @include res(padding-top,0.6rem,(xs:15px));
          @include res(padding-bottom,0.5rem,(xs:15px));
          .num{
            font-family: 'din';
            display: flex;
            align-items: center;
            color: #000;
            @include res(font-size, 0.3rem);
            &::before{
              content: '';
              display: block;
              background-color: #000;
              @include res(width, 0.24rem);
              @include res(height, 0.03rem);
              @include res(margin-right,0.05rem);
            }
          }
          .title{
            color: #000;
            font-weight: bold;
            @include res(letter-spacing,0.04rem);
            @include res(font-size,0.48rem);
            @include res(margin-bottom,0.2rem);
            @include res(margin-top,0.4rem);
          }
          .desc{
            color: #333;
            @include res(letter-spacing,0.03rem);
            @include res(height,1.65rem,(lg:0.86rem,md:68px));
            @include res(line-height,2.4, 1.6 / 2.4);
            @include res(font-size,0.2rem,(md:16px));
            @include res(margin-bottom,0,(lg:0.2rem,xs:20px));
          }
          .link{
            @include res(letter-spacing,0.02rem);
            @include res(font-size, 0.18rem,(md:18px));
            a{
              color: #384dc3;
              transition: color .3s;
              &:hover{
                color: #cb4444;
                .iconfont{
                  transform: translateX(5px);
                }
              }
              .iconfont{
                transition: transform .3s;
                display: inline-block;
                margin-left: 0.05rem;
                font-size: 0.16rem;
              }
            }
          }
        }
      }
      .swiper-btn{
        border: 1px solid #c6c4d0;
        position: absolute;
        display: flex;
        align-items: stretch;
        transition: all .3s;
        @include res(right,100%,(sm:0));
        @include res(top,calc(100% - 0.3rem),(sm:0));
        &:hover{
          border: 1px solid $color-main;
          background-color: $color-main;
          &::after{
            background-color: rgba(255,255,255,.7);
          }
          .swiper-button-prev,.swiper-button-next{
            color: #fff;
          }
        }
        &::after{
          content: '';
          display: block;
          background-color: #939296;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%,-50%,0);
          @include res(width,0.02rem);
          @include res(height, 0.17rem);
        }
        .swiper-button-prev,.swiper-button-next{
          position: static;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          background-image: none;
          transition: color .3s;
          color: #626264;
          @include res(width, 1.55rem);
          @include res(height, 0.85rem);
          &::after{
            font-family: 'iconfont';
            transition: transform .3s;
            display: inline-block;
            @include res(font-size,0.3rem);
          }
        }
        .swiper-button-prev{
          &::after{
            content: '\e625';
          }
          &:hover{
            &::after{
              transform: translateX(-.05rem);
            }
          }
        }
        .swiper-button-next{
          &::after{
            content: '\e624';
          }
          &:hover{
            &::after{
              transform: translateX(.1rem);
            }
          }
        }
      }
    }
  }
  .mob-cont{
    @include res(display,none,(sm:block));
    ul{
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      @include res(display,flex,(xs:block));
      li{
        flex-shrink: 0;
        position: relative;
        @include res(width,48%,(xs:100%));
        @include res(margin-bottom,4%);
        .item-t{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.5);
          box-sizing: border-box;
          color: #fff;
          @include res(padding-top,10%);
          @include res(padding-left,10%);
          .num{
            display: flex;
            align-items: center;
            font-family: 'din';
            &::before{
              content: '';
              display: block;
              width: 14px;
              height: 2px;
              background-color: #fff;
              margin-right: 5px;
            }
          }
          .title{
            font-size: 22px;
            font-weight: bold;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .desc{
            opacity: .8;
            margin-bottom: 15px;
          }
          .link{
            font-size: 14px;
            .iconfont{
              font-size: 12px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  
}

.section2{
  position: relative;
  @include res(padding-top,1.5rem,(xs:0.5rem));
  .index-common-title{
    position: relative;
    z-index: 2;
    .common-link{
      @include res(display,null,(xs:none));
    }
  }
  .cont{
    position: relative;
    z-index: 2;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(background-color,#fff,(sm:transparent));
    @include res(display,flex);
    @include res(flex-direction,row,(xs:column-reverse));
    @include res(padding-top,0.4rem,(sm:0));
    @include res(padding-left,0.4rem,(sm:0));
    @include res(padding-bottom,0.4rem,(sm:0));
    &::after{
      content: '';
      width: 100%;
      height: 100%;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: calc(100% - 1px);
      @include res(display,block,(sm:none));
    }
    .index-new{
      flex-shrink: 0;
      background-color: #f8f8f8;
      position: relative;
      @include res(width,29%,(sm:48%,xs:100%));
      @include res(margin-bottom,0,(xs:15px));
      &:first-child{
        @include res(width,38%,(sm:100%));
        @include res(margin-bottom,0,(sm:20px,xs:0));
        .item-tag{
          background-color: rgba($color-main,1);
        }
      }
      &:hover{
        .item-img b{
          transform: scale(1.1);
        }
        .item-t .item-title{
          color: $color-main;
        }
      }
      .item-tag{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(0,0,0,.3);
        color: #fff;
        line-height: 2;
        white-space: nowrap;
        @include res(font-size,null,(sm:14px));
        @include res(padding-left,0.15rem);
        @include res(padding-right,0.15rem);
      }
      ul{
        @include res(padding-top, 0.45rem,(sm:35px));
        @include res(padding-bottom, 0.45rem,(sm:15px));
        @include res(padding-left, 0.25rem,(sm:15px));
        @include res(padding-right, 0.25rem,(sm:15px));
        li{
          &:not(:last-child){
            @include res(margin-bottom,0.25rem);
          }
          &:hover{
            .item-title{
              color: $color-main;
            }
          }
          .item-title{
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: color .4s;
            @include res(font-size,0.2rem,(md:18px,xs:16px));
          }
          .item-date{
            color: #7c7c7c;
            @include res(font-size,null,(xs:12px));
            @include res(margin-top, 0.05rem);
          }
        }
      }
      .item-t{
        @include res(padding-top, 0.35rem);
        @include res(padding-bottom, 0.35rem);
        @include res(padding-left, 0.25rem,(sm:15px));
        @include res(padding-right, 0.25rem,(sm:15px));
        .item-title{
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: all .4s;
          @include res(font-size,0.2rem,(md:18px,xs:16px));
        }
        .item-date{
          color: #7c7c7c;
          display: flex;
          align-items: center;
          @include res(font-size,null,(xs:12px));
          @include res(margin-top, 0.1rem);
          @include res(margin-bottom, 0.15rem);
          .iconfont{
            font-size: 12px;
            @include res(margin-right,0.1rem);
          }
        }
        .item-desc{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          color: #666;
          @include res(font-size,null,(xs:14px));
          text-align: justify;
        }
      }
      .mob-more{
        width: 100%;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f1;
        color: $color-main;
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        @include res(display,none,(xs:flex));
        .iconfont{
          font-size: 14px;
          margin-left: 10px;
        }
      }
    }
  }
  .bg-canvas{
    width: 100%;
    position: absolute;
    left: 0;
    overflow: hidden;
    background-size: 100% auto !important;
    @include res(bottom,30%,(xs:50%));
    @include res(background,url(./images/index_img5.png) no-repeat bottom center #f5f4fa,(sm:url(./images/index_img5_mob.png) no-repeat bottom center #f5f4fa));
    @include res(height,100vh,(xs:100%));
  }
}

.section3{
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-size: 100% auto !important;
  @include res(padding-top,1rem,(xs:50px));
  @include res(padding-bottom,1.5rem,(xs:50px));
  @include res(display,flex,(sm:block));
  @include res(background,null,(sm:url('./images/index_mob_img5.jpg') no-repeat bottom center));
  &.container{
    @include res(padding-right,0);
    @media screen and (max-width:992px) {
      @include res(padding-right, 12.5%, 5 / 12.5);
    }
  }
  .cont{
    position: relative;
    z-index: 2;
    @include res(display,block,(sm:none));
    @include res(margin-right,5%,(md:5%,sm:0));
    .sec3-desc{
      @include res(width,5.7rem,(sm:100%));
      @include res(padding-left,0.2rem);
      @include res(margin-bottom,1.5rem,(xs:0.5rem));
      .t{
        @include res(line-height, 1.8, 1.6 / 1.8);
        @include res(font-size,0.2rem,(md:16px));
        @include res(margin-bottom,0.45rem);
        text-align: justify;
      }
    }
    .sec3-desc2{
      border-left: solid $color-main;
      @include res(white-space,nowrap,(md:normal));
      @include res(line-height, 1.8, 1.6 / 1.8);
      @include res(font-size,0.2rem,(md:16px));
      @include res(border-left-width,0.04rem);
      @include res(margin-left,0.2rem);
      @include res(padding-left,0.35rem);
      .num{
        font-family: 'din';
        line-height: .8;
        @include res(font-size,0.72rem);
        @include res(margin-bottom,0.35rem);
      }
    }
  }
  
  .bg{
    flex-shrink: 0;
    position: relative;
    @include res(display,block,(sm:none));
    @include res(width, 8.8rem,(xs:300px));
    @include res(margin,null,(sm:0 auto));
    @include res(margin-top,0,(sm:20px));
    // @include res(height, 9.9rem,(md:10.8rem,xs:300px));
    img{
      display: block;
      width: 100%;
      height: auto;
    }
    ul{
      li{
        position: absolute;
        top: 1%;
        left: 1%;
        @include res(width,.01rem,(md:1px));
        @include res(height,.01rem,(md:1px));
        span{
          display: block;
          border-radius: 50%;
          background-color: $color-main;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          transform: translate3d(-50%,-50%,0);
          @include res(width,.18rem,(md:10px,xs:6px));
          @include res(height,.18rem,(md:10px,xs:6px));
        }
        &::before,&::after{
          content: '';
          display: block;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%,-50%,0) scale(0);
          background-color: rgba(74,93,200,.6);
          z-index: 1;
          transform-origin: center;
          animation: wave 2s infinite;
          transition: $anime-duration $anime-bezier;
          @include res(width,.7rem, (md:50px,xs:30px));
          @include res(height,.7rem, (md:50px,xs:30px));
        }
        // &::after{
        //   animation-delay: .8s;
        // }
        &:nth-child(4n+1){
          &::before{
            animation-delay: 0s;
          }
          &::after{
            animation-delay: .8s;
          }
        }
        &:nth-child(4n+2){
          &::before{
            animation-delay: .5s;
          }
          &::after{
            animation-delay: 1.3s;
          }
        }
        &:nth-child(4n+3){
          &::before{
            animation-delay: 1s;
          }
          &::after{
            animation-delay: 1.8s;
          }
        }
        &:nth-child(4n+4){
          &::before{
            animation-delay: 1.5s;
          }
          &::after{
            animation-delay: 2.3s;
          }
        }
        &:nth-child(1){
          top: 22%;
          left: 18.5%;
          // &::before{
          //   animation-delay: 0s;
          // }
          // &::after{
          //   animation-delay: .8s;
          // }
        }
        &:nth-child(2){
          top: 4%;
          left: 42%;
          // &::before{
          //   animation-delay: .5s;
          // }
          // &::after{
          //   animation-delay: 1.3s;
          // }
        }
        &:nth-child(3){
          top: 4%;
          left: 79.5%;
          // &::before{
          //   animation-delay: 1s;
          // }
          // &::after{
          //   animation-delay: 1.8s;
          // }
        }
        &:nth-child(4){
          top: 14.5%;
          left: 87%;
          // &::before{
          //   animation-delay: 1.5s;
          // }
          // &::after{
          //   animation-delay: 2.3s;
          // }
        }
        &:nth-child(5){
          top: 26%;
          left: 79%;
          // &::before{
          //   animation-delay: 0s;
          // }
          // &::after{
          //   animation-delay: .8s;
          // }
        }
        &:nth-child(6){
          top: 19.5%;
          left: 63.5%;
          // &::before{
          //   animation-delay: .8s;
          // }
          // &::after{
          //   animation-delay: 1.6s;
          // }
        }
        &:nth-child(7){
          top: 26.5%;
          left: 50%;
          // &::before{
          //   animation-delay: .1s;
          // }
          // &::after{
          //   animation-delay: .9s;
          // }
        }
        &:nth-child(8){
          top: 50%;
          left: 50.5%;
          // &::before{
          //   animation-delay: .5s;
          // }
          // &::after{
          //   animation-delay: 1.3s;
          // }
        }
        &:nth-child(9){
          top: 61.5%;
          left: 60.5%;
          // &::before{
          //   animation-delay: .3s;
          // }
          // &::after{
          //   animation-delay: 1.1s;
          // }
        }
        &:nth-child(10){
          top: 64.5%;
          left: 73%;
          // &::before{
          //   animation-delay: .6s;
          // }
          // &::after{
          //   animation-delay: 1.4s;
          // }
        }
        &:nth-child(11){
          top: 56%;
          left: 83%;
          // &::before{
          //   animation-delay: .4s;
          // }
          // &::after{
          //   animation-delay: 1.2s;
          // }
        }
        &:nth-child(12){
          top: 80%;
          left: 81.5%;
          // &::before{
          //   animation-delay: .8s;
          // }
          // &::after{
          //   animation-delay: 1.6s;
          // }
        }
      }
    }
  }
  .mob-cont{
    @include res(display,none,(sm:block));
    .index-common-title{
      text-align: center;
    }
    .desc-t{
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .num-cont{
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      text-align: center;
      flex-wrap: wrap;
      li{
        flex-shrink: 0;
        width: 50%;
        margin-bottom: 20px;
        .num{
          color: $color-main;
          font-family: 'din';
          font-size: 30px;
        }
        p{
          color: #666;
          font-size: 14px;
        }
      }
    }
  }
}
@keyframes wave {
  0%{
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(0);
  }
  100%{
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}

footer .friend-link{
  @include res(display,block,(md:none));
}